import React from "react";
import styles from "./MainLoading.module.css";

export default function MainLoading() {
  return (
    <div className={styles.container}>
      <div className={styles.header}></div>
      <div className={styles.hero}>
        {/* <div className={styles.heading}>
          Nxcar for Used Cars
        </div>
        <div className={styles.sub_heading}>
          Making Car Buying and Selling Smarter, Transparent, and More Convenient
        </div> */}
      </div>

      <div className={styles.header}></div>
      <div className={styles.header}></div>
      <div className={styles.header}></div>
      <div className={styles.header}></div>
    </div>
  );
}
